.App {
  text-align: center;
}

.nonetext {
  text-align: center;
  margin-top: 200px;
  color: #000000;
}

.popup {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  /* margin-top: 50px; */
}

.popup-inner {
  background-color: rgba(255, 255, 255, 0.49);
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  width: 25%;
  height: 600px;
}

.popup-inner h2 {
  color: #000000;
  margin-top: 0;
  text-align: center;
}

.popup-inner label {
  color: #000000;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.popup-inner input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #e7e5e5;
  margin-bottom: 10px;
}

.text-label {
  display: flex !important;
  flex-direction: column;
  font-weight: bold;
}

.popup-inner textarea {
  background-color: #e7e5e5;
  height: 100px;
}

.close-btn {
  cursor: pointer;
  text-align: right;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: #2cd5c4;
  border-color: #2cd5c4;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #000000;
  color: aliceblue;
  border-color: #2cd5c4;
}

/* .popup-inner button[type="submit"],
  .popup-inner button[type="button"] {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  } */

/* .popup-inner button[type="submit"]:hover,
  .popup-inner button[type="button"]:hover {
  background-color: #45a049;
  }
  
  .popup-inner button[type="submit"] {
  margin-top: 20px;
  }
  
  .popup-inner button[type="button"] {
  margin-top: 20px;
  background-color: #f44336;
  }
  
  .popup-inner button[type="button"]:hover {
  background-color: #da190b;
  }
  
  .popup-inner button[type="submit"]:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
  }
  
  .popup-inner button[type="button"]:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
  } */








.whatsapp-btn{
  position:fixed;
  z-index: 100;
  opacity: 100;
  bottom: 2%;
  right: 1%;
}
.whatsapp-btn img{
  height: 80px;
  width: 100%;
  background-color: transparent;
  /* -webkit-box-shadow: 0px 7px 15px 0px #f5ecece3;
  box-shadow: 0px 7px 15px 0px #edeaea60; */
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #2cd5c4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav {
  background-color: #000000;
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
}

.navinner {
  background-color: #000000;
}

.navItem {
  color: aliceblue;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.navItem:hover {
  color: #2cd5c4;
}

/* .navItem .dropdown:hover .dropdown-menu {
  display: block;
} */
#basic-nav-dropdown {
  color: aliceblue;
}
#basic-nav-dropdown:hover{
  color: #2cd5c4;
}
.dropdown-menu.show{
  background-color: rgba(240, 248, 255, 0.096);
  padding: 0;
  margin: 0;
}
#nav-sub-Item{
  color: aliceblue;
  background-color: rgba(53, 54, 54, 0.937);
  padding: 15px;
  margin-bottom: 2px;
}
#nav-sub-Item:hover{
  color: #2cd5c4;
}












.loginpage {
  /* min-height: 80vh; */
  margin-top: 100px;
  padding: 100px;
  background: url(../../getpc-app/src/components/Home/HomeSec5/Simg2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
}

#login-head {
  font-family: 'Orbitron', sans-serif;
  font-size: 30px;
  font-weight: bolder;
  line-height: 54px;
  letter-spacing: 0.2em;
  text-align: center;
  color: aliceblue;
}

.Overlay2 {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: .5rem;
  background: #0b0b0b8a;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.loginpage p {
  color: #bdbbbb;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.loginpage label {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: aliceblue;
  padding: 15px;
}

.loginpage input {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: #F5F5F5;
  font-size: 20px;
  border: none;
  /* width: 100%; */
}
.check-text{
  color: aliceblue;
  margin-left: 5px;
}

/* .loginpage button {
  background-color:aquamarine;
  color:#000000;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 45%;
  padding-right: 45%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: none;
} */

.Signuppage {
  background: url(../../getpc-app/src/components/Home/HomeSec5/Simg2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.Signuppage p {
  color: #bdbbbb;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.Signuppage label {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: aliceblue;
  padding: 15px;
}

.Signuppage input {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: #F5F5F5;
  font-size: 20px;
  border: none;
  width: 100%;
}

.btnn4 {
  /* background-color: aquamarine;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 43%;
  padding-right: 43%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: none; */

  /* background-color:aquamarine;
  color:#000000;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 45%;
  padding-right: 45%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: none; */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: #2cd5c4;
  color: aliceblue;
  border-style: 4px solid;
  border-color: #2cd5c4;
  font-weight: bold;
  margin-top: 5px;
}
.btnn4:hover{
  background-color: aliceblue;
  color: #000000;
  border-color: #2cd5c4;
}

.Signuppage .box1 {
  margin-top: 190px;
  margin-bottom: 100px;
}


.Passwordpage {
  background: url(../../getpc-app/src/components/Home/HomeSec5/Simg2.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.Passwordpage h4 {
  font-family: 'Chewy', cursive;
  font-size: 30px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0.2em;
  text-align: center;
  color: #15658D;
}

.Passwordpage p {
  color: #bdbbbb;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.Passwordpage label {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: aliceblue;
  padding: 15px;
}

.Passwordpage input {
  padding: 10px;
  border-radius: 18px;
  border: none;
  background: #F5F5F5;
  font-size: 20px;
  border: none;
}

/* .btnn4 {
  background-color: #2cd5c4;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 43%;
  padding-right: 43%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border: none;
} */

.Passwordpage .box1 {
  margin-top: 190px;
  margin-bottom: 200px;
}








/* .home-shade{
  background: linear-gradient(
      #000000 20%,
      rgba(80, 79, 79, 0.897) 67%,
      rgba(101, 101, 101, 0.942) 81%);
} */



.Indexp {
  /* background: url(https://cdn.pixabay.com/video/2019/10/10/27725-365890983_large.mp4); */
  /* height: 650px; */
  background-color: #000000;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.Indexp::before {
  position: absolute;
  content: '';
  /* background: url(https://themvp.in/catalog/view/assets/img/geo-wave-bg-min.png); */
  background: transparent url(./components/Home/HomeSec2/Indexbg.png) no-repeat bottom center;
  width: 100%;
  height: 100%;
  background-size: 100%;
  /* opacity: 2; */
  filter: brightness(18)!important;
}


.text2 {
  font-family: 'Orbitron', sans-serif;
  font-size: 60px;
  color: #000000;
  background-color: aliceblue;
  font-weight: bolder;
  /* margin-top: 10px; */
  margin-bottom: 15px;
  width: 50%;
  text-align: center;
  margin-top: 10px;
  z-index: 2;
  position: relative;
}

/* .caro1{
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
} */

.Idx1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* .Itext{
  text-align: left;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
}

.Itext p{
  color: aliceblue;
  font-size: large;
  letter-spacing: 1px;
  font-weight: 40px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

.index-btn{
  position: relative;
  background-color: #000000;
  border-width: 2px;
  border-color: #2cd5c4;
  padding: 15px;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-weight: 8 0px;
  cursor: pointer;
  font-weight: bold;
  font-size: larger;
  /* opacity: 9; */
  z-index: 9;
  margin-top: 10px;
  width: 50%;
}


.btnn {
  position: relative;
  background-color: #000000;
  border-width: 2px;
  border-color: #2cd5c4;
  padding: 10px;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-weight: 8 0px;
  cursor: pointer;
  font-weight: bold;
  /* opacity: 9; */
  z-index: 9;
  margin-top: 5px;
}

.btnn:hover{
  background-color: #2cd5c4;
  border-color: #2cd5c4;
  color: #000000;
  font-weight: 50px;
}

.index-btn:hover {
  background-color: #2cd5c4;
  border-color: #2cd5c4;
  color: #000000;
  font-weight: 50px;
}

#sp1 {
  font-family: 'Orbitron', sans-serif;
  font-size: 60px;
  color: aliceblue;
  font-weight: bolder;
  /* text-align: left; */
}


#imag1 {
  display: flex;
  /* height: 411.86px; */
  width: 100%;
  opacity: 10;
  height: 500px;


  transform: translatey(0);
  animation: float 4s ease-in-out infinite;

  /* animation: blink 5s linear infinite; */
}


/* @keyframes blink {
  0%, 100% {opacity: 1;}
  50% {opacity: 0;}
} */

@keyframes float {
  0% {
    transform: translatey(0);
  }

  50% {
    transform: translatey(-20px);
  }

  100% {
    transform: translatey(0);
  }

}


@media only screen and (max-width: 600px) {
  #imag1 {
    width: 100%;
    height: 25%;
    overflow: hidden;
  }

  .caro1 {
    overflow: hidden;
  }
}


.Usp1 {
  padding-top: 100px;
  margin-top: 200px;
  padding-bottom: 50px;
}

.Usp {
  padding-top: 50px;
  margin-top: 50px;
  padding-bottom: 50px;
}

#sp2 {
  font-family: 'Orbitron', sans-serif;
  font-weight: bold;
  font-size: 50px;
  color: #000000;
  text-align: center;

}

.contain1 {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.contain1-text {
  padding: 20px;
}

.contain1-image {
  padding: 20px;
  display: flex;
  justify-content: right;
}

.contain1 p {
  margin-top: 2px;
  letter-spacing: 1px;
  line-height: 2;
  font-weight: 30px;
  font-size: large;
  text-wrap: wrap;
}

.contain1 img {
  display: flex;
  /* flex-wrap: wrap; */

  height: 441px;
  border-radius: 13px;
  border-style: none;
  /* -webkit-box-shadow: 5px 5px 11px -5px #000000; 
  box-shadow: 5px 5px 11px -5px #000000; */
}

@media only screen and (max-width: 600px) {

  .contain1 {
    text-align: center;
  }

  .contain1 img {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .contain1 img {
    width: 100%;
    overflow: hidden;
  }
}

.blob {
  position: absolute;
  opacity: 0.5;
  width: 250px;
  top: 90%;
  aspect-ratio: 1/1;
  animation: animate 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite alternate forwards;
  filter: blur(40px);
  z-index: -1;
  background: linear-gradient(47deg,
      rgb(114, 111, 112) 21%,
      rgb(0 164 173) 67%,
      rgb(43, 212, 246) 81%);

  /*  Optional  */
  /*   box-shadow: inset 50px 50px 17px 0px #ff588b,
    inset 100px 100px 17px 0px #66319f; */
}

@keyframes animate {
  0% {
    translate: 40% -25%;
    border-radius: 60% 40% 30% 70% / 100% 85% 92% 74%;
  }

  50% {
    translate: 0% 13%;
    border-radius: 20% 71% 47% 70% / 81% 15% 22% 54%;
    rotate: 41deg;
    scale: 1.15;
  }

  100% {
    translate: -45% 39%;
    border-radius: 100% 75% 92% 74% / 60% 80% 30% 70%;
    rotate: -60deg;
    scale: 1.05;
  }
}


.Offp {
  /* background-color: #000000; */
  background: radial-gradient(
      rgba(89, 89, 89, 0.957) 30%,
      rgba(24, 24, 24, 0.897) 67%,
      rgba(20, 20, 20, 0.942) 81%);
  padding-top: 50px;
  /* margin-top: 50px; */
  padding-bottom: 50px;
}

.contain2 {
  text-align: center;
}

.contain21 {
  display: flex;
  flex-wrap: wrap;
  color: aliceblue;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.Offp-contain {
  padding: 25px;
}




.contain21 img {
  height: 100px;
  margin-top: 20px;
}

.contain21 h5 {
  margin-top: 20px;
}

.contain21 p {
  margin-top: 20px;
  font-size: large;
}

#sp3 {
  font-family: 'Orbitron', sans-serif;
  font-weight: bold;
  font-size: 50px;
  color: #2cd5c4;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {

  .contain21 {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .contain21 Col {
    margin-top: 50px;
    padding: 10px;
  }
}



.Servp {
  position: relative;
  /* margin-bottom: 50px; */
}

.caro21 {
  position: relative;
  height: 600px;
}

.IdxImage {
  transition: 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99);
  will-change: transform;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
  width: 100%;
  min-height: 100%;
}

.caro21 img {
  /* display: flex; */
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}

.overlay {
  position: absolute;
  background-size: cover;

}

.Overlay1 {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: .5rem;
  background: #0b0b0b97;
  margin-bottom: 50px;
  background-size: cover;
  height: 100%;
}

.text1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.text1 span {
  display: flex;
  justify-content: center;
  font-weight: 40px;

}

.text1 h3 {
  color: aliceblue;
  align-items: center;
  margin-top: 40px;
  padding-top: 40px;
  font-size: 45px;
  letter-spacing: 1px;
}

.text1 p {
  color: aliceblue;
  font-size: x-large;
  margin-top: 40px;
  font-weight: 20px;
  text-wrap: wrap;
}

.service-btn {
  position: relative;
  background-color: #2cd5c4;
  color: #000000;
  border-style: solid;
  border-width: 2px;
  border-color: #2cd5c4;
  padding: 10px;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  /* opacity: 9; */
  z-index: 9;
}

.service-btn:hover {
  background-color: aliceblue;
  border-color: #2cd5c4;
  /* border: none; */
  color: #000000;
  font-weight: 50px;
}


/* .carousel-control-prev {
  left: -150px;
  background-color: black;
}
.Servp .carousel-control-prev-icon {
  padding: 18px;
  border-radius: 50%;
  background-color: aqua;
}
.carousel-control-next {
  left: 1220px;
  background-color: black;
}
.Servp .carousel-control-next-icon {
  padding: 18px;
  border-radius: 50%;
  background-color: aqua;
} */

/* @media only screen and (max-width: 550px){
  .carousel-control-prev {
    left: -40px;
    overflow-x: hidden;
  }
  .carousel-control-next {
    left: 300px!important;
  }
}

@media only screen and (max-width: 770px) {
  .carousel-control-prev {
    left: -80px;
    overflow-x: hidden;
  }
  .carousel-control-next {
    
    left: 460px!important;
  }
}

@media only screen and (max-width: 990px) {
  .contain4{
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .contain4 img{
    text-align: center;
    width: 100%;
  }
  .contain4 h2{
    margin-top: 50px;
    text-align: center;
  }
  .contain4 p{
    text-align: center;
  }
  
}

@media screen and (max-width: 1400px){
  .contain3 .carousel{
    padding: 10px;
    margin: 20px;
  }
  .contain4 Col{
    padding: 10px;
  }
  .contain4 h2{
    font-size: 25px;
  }
  .contain4 p{
    font-size: 18px;
  }
  
} */



.Bottomp {
  background-color: #000000;
  color: aliceblue;
  margin-bottom: 0;
  padding-bottom: 50px;
  bottom: 0;
  -webkit-box-shadow: 0px 7px 15px 0px #8f8f8f7b;
  box-shadow: 0px 7px 15px 0px #8f8f8f60;
}

.contain3 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.contain3 Col {
  display: flex;
  flex-direction: column;
}

.contain3 p {
  font-size: 20px;
  margin-bottom: 5px;
}

.contain3 h5 {
  font-size: 20px;
  margin-bottom: 5px;
}

.contain3 Col ul {
  text-decoration: none;
  list-style-type: none;
}

.contain3 li {
  text-decoration: none;
  list-style: none;
  margin-bottom: 5px;
  font-size: 20px;
}









.Reviewp {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  /* background: radial-gradient(
      rgba(198, 240, 239, 0.957) 30%,
      rgba(102, 246, 241, 0.676) 67%,
      rgba(11, 236, 232, 0.741) 81%); */
}
.review-contain{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  position: relative;
  margin: 20px;
  padding: 20px;
  width: 90%;
  /* height: 400px; */
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -1px #3DAFC4;
  box-shadow: 0px 0px 15px -1px #6cdbfab1;
}

.review-contain p::before{
  content: url('./components/Home/HomeSec6/BracketIcon1.svg');
  top: 10%;
  left: 7%;
  position: absolute;
}
.review-contain p::after{
  content: url('./components/Home/HomeSec6/BracketIcon2.svg');
  bottom: 40%;
  right: 10%;
  position: absolute;
}

.slick-prev:before {
  color:#3597D8;
}
.slick-next:before {
  color:#3597D8;
}
.review h3 {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #246F94;
}






/* .contain5 {
  border: 0px;
  -webkit-box-shadow: 0px 0px 15px -1px #3DAFC4;
  box-shadow: 0px 0px 15px -1px #6cdbfab1;
  min-height: 400px;
  position: relative;
  width: 92%;
  border-radius: 19px;
  text-align: center;
} */

/* .contain5::after {
  content: url('./components/Home/HomeSec6/Wavebg1.png');
  bottom: -7px;
  right: 1px;
  left: 0px;
  position: absolute;
} */


.customerR p {
  text-align: left;
  padding: 40px;
  padding-top: 50px;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 24px; */
  letter-spacing: 0em;
  color: #797979;
  text-wrap: wrap;
}

.customerR ::before {
  content: url('./components/Home/HomeSec6/BracketIcon1.svg');
  top: 5%;
  left: 10%;
  position: absolute;
}

.customerR ::after {
  content: url('./components/Home/HomeSec6/BracketIcon2.svg');
  bottom: 0;
  right: 20%;
}


.wave h5 {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 700;
  /* line-height: 46px; */
  letter-spacing: 0em;
  color: #000000;
}

.wave p {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 500;
  /* line-height: 46px; */
  letter-spacing: 0em;
  color: #000000;
  margin: 0;
}

.stars {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-btn {
  position: relative;
  background-color: #2cd5c4;
  color: #000000;
  border-width: 2px;
  border-color: #2cd5c4;
  padding: 10px;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  /* opacity: 9; */
  z-index: 9;
}

.connect-btn:hover {
  background-color: aliceblue;
  border-color: #2cd5c4;
  color: #000000;
  font-weight: 50px;
}


/* SOCIAL MEDIA PAGE */


.socialMedia {
  background: linear-gradient(-62deg,
      rgba(7, 7, 7, 0.957) 21%,
      rgba(15, 14, 14, 0.628) 67%,
      rgba(22, 22, 22, 0.298) 81%);
  position: relative;
  /* min-height: 100vh; */
  padding-top: 50px;
  padding-bottom: 100px;
}

.smedia-contain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.smedia-contain a {
  text-decoration: none;
}

.smedia-contain p {
  color: aliceblue;
  font-size: 30px;
  text-align: center;
}

.smedia-contain img {
  /* object-fit: scale-down; */
  width: 100%;
}


/* BRANDS PAGE */

.Brandp{
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 10px;
    padding-bottom: 10px;
    border-style: dashed;
    border-color: #2cd5c4cc;
    margin-left: -3px;
}
@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 7));
  }
}
.slder{
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  background-color: #2cd5c459;
  
  /* border-style: solid; */
  /* border-color: #051e5d; */
}
.slder .slde-track{
  animation: scroll 35s linear infinite;
  display: flex;
  width: calc(250px * 14);
  
}
.slder .slde{
  height: 100px;
  width: 100%;

}









/*  BLOG TOPICS PAGE  */
.Blogpage {
  margin-top: 180px;
  background-color: #000000;
}

.SearchBar {
  margin-left: -100px;
}

.SearchBar img {
  position: absolute;
  top: 23%;
  background-size: contain;
  left: 72%;
}

.Blogpage input {
  padding-left: 50%;
  /* padding-right: 30%; */
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
  background-color: #F5F5F5;
  border-radius: 18px;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-left: -100px; */
}

.Blogcontent {
  justify-content: space-between;
}

.Blogcontent img {
  width: 90%;
  object-fit: contain;
  overflow: hidden;
}

.Blogcontain {
  margin-top: 100px;
  margin-bottom: 50px;
}

.Blogpage .col {
  /* -webkit-box-shadow: 0px 7px 15px 0px #8f8f8f7b;
  box-shadow: 0px 7px 15px 0px #8f8f8f60; */
  border-radius: 5px;
  text-align: left;
  width: 425px;
  cursor: pointer;
  background-color: #000000;
}

.Blogpage h4 {
  font-size: 20px;
  font-weight: 40px;
  text-align: left;
  color: aliceblue;
}

.Blogpage p {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;
  text-align: center;
}

.Blogpage .blogpara {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
}

.BDcol21 h5 {
  font-family: 'Nunito', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
}

.BDcol21 span {
  font-family: 'Nunito', sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
  ;
}

.BDpara1 {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;
}

.BDpara2 {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
}

.BDcontent {
  margin-top: 200px;
  /* margin-left: 80px; */
  /* margin-right: 80px; */
}

.BDcontent1 {
  margin-bottom: 50px;
}

.BDrow {
  /* padding: 30px; */
  -webkit-box-shadow: 0px 0px 15px -2px #707070;
  box-shadow: 0px 0px 15px -2px #707070;
  margin-bottom: 30px;
  border-radius: 30px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.BDrow h5 {
  font-family: 'Chewy', cursive;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #15658D;
}

.BDrow button {
  padding: 5px;
  border: 2px solid #3597D8;
  color: #3597D8;
  background-color: #FFFFFF;
  border-radius: 8px;
}

.BDrow button:hover {
  background-color: #3597D8;
  color: #FFFFFF;
}

.BDcol21 {
  padding: 50px;
  -webkit-box-shadow: 0px 0px 15px -2px #707070;
  box-shadow: 0px 0px 15px -2px #707070;
  border-radius: 20px;
  height: 1100px;
}

.BDcol22 {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BDcol1 {
  width: 40px;
  padding: 20px;
}

.BDcol1 p {
  font-family: 'Nunito', cursive;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
}


.BDcontent2 {
  text-align: center;
}

.BDcontent2 h5 {
  font-family: 'Nunito', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #636363;
  margin-bottom: 30px;
}

.BDcontent2 p {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;
  margin-bottom: 30px;
}

.BDcontent2 li {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;
}

.BDcontent2 h6 {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;

  /* font-family: Nunito;
font-size: 26px;
font-weight: 400;
line-height: 35px;
letter-spacing: 0em;
text-align: left; */
}

.BDcontent2 h6 span {
  font-family: 'Nunito', sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F8F8F;
}


/* CONTACT PAGE  */

.Contact1p {
  margin-top: 150px;
  padding-top: 100px;
  background-color: #000000;
  padding-bottom: 50px;
  /* margin-bottom: 50px; */
  /* position: relative; */
}

.Contact1p::before {
  position: absolute;
  content: '';
  background: transparent url(./components/Home/HomeSec2/Indexbg.png) no-repeat bottom center;
  width: 100%;
  height: 100%;
  background-size: 100%;
  opacity: 1;
  overflow: hidden;
}

.contact1-contain {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.contact1-contain p {
  color: aliceblue;
  text-align: center;
  font-size: 20px;
}

.Contact2p {
  background-color: #61dafb58;
  /* min-height: 80vh; */
}

.contact2-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact2-subcontain {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  overflow: hidden;
}

#gmail-img{
  height: 100px;
}

#contact2-box {
  background-color: rgba(240, 248, 255, 0.387);
  /* color: aliceblue; */
  color: #000000;
  /* padding: 30px; */
  text-align: center;
  margin: 20px;
  opacity: 10;
  border-radius: 10px;
  /* background: url('../../getpc-app/src/components/Contact/ContactSec2/Vector.svg');
  background-size: 100%; */
  z-index: 25;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 400px;
  -webkit-box-shadow: 0px 0px 15px -2px #707070;
  box-shadow: 0px 0px 15px -2px #707070;
}

/* #contact-box img{
  width: 100%;
  height: 50px;
  position: relative;
  object-fit: contain;
  overflow: hidden;
} */

#caller{
  text-decoration: none;
  color:#000000;
  font-size:x-large;
  margin-top: 10px;
}

#contact2-box span {
  font-size: 30px;
  font-weight: bold;
  background-color: aliceblue;
  color: #000000;
  -webkit-box-shadow: 0px 0px 15px -2px #707070;
  box-shadow: 0px 0px 15px -2px #707070;
  padding: 10px;
  width: 100%;
}

#contact2-box p {
  font-size: 18px;
  margin-top: 20px;
  /* position: absolute; */
}
.whatsapp-btn-contact{
  z-index: 100;
  opacity: 100;
  margin-top: 10px;
}
.whatsapp-btn-contact img{
  height: 80px;
  width: 100%;
  background-color: transparent;
  /* -webkit-box-shadow: 0px 7px 15px 0px #f5ecece3;
  box-shadow: 0px 7px 15px 0px #edeaea60; */
}



/* PRODUCTS PAGE */

.Eng-content{
  padding-top: 250px;
  /* background: linear-gradient(
      rgba(225, 246, 246, 0.957) 30%,
      rgba(163, 252, 252, 0.957) 67%,
      rgba(114, 249, 249, 0.748) 81%); */

  background-color: rgba(163, 252, 252, 0.419);
}
.prod-content{
  margin: 50px;
  margin-bottom: 0;
  /* padding-top: 50px; */
  padding-bottom: 50px;
}
.prod-list{
  display: flex;
  align-items: center;
  justify-content: center;
}
.prod-box{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  /* background-color: white; */
  background: linear-gradient(
    rgba(55, 54, 54, 0.278) 45%,
      rgba(55, 54, 54, 0.507) 55%,
      rgba(55, 54, 54, 0.818) 81%);
  overflow: hidden;
  padding: 50px;
  /* margin: 5px; */
  -webkit-box-shadow: 0px 7px 15px 0px #4b4a4ae3;
  box-shadow: 0px 7px 15px 0px #7b7a7ac1;
}
.prod-items{
  display: flex;
  align-items: center;
  justify-content: center;
}
.prod-items h4{
  font-size: large;
  font-weight: bold;
}
.prod-items p{
  font-size: large;
  text-wrap: nowrap;
}
.prod-items h3{
  font-weight: bold;
}

.prev-price{
  text-decoration: line-through;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #000000;
}
.price-tag{
  /* background-color: #000000; */
  color: aliceblue;
}
.prod-btn{
  position: relative;
  background-color: #2cd5c4;
  color: #000000;
  border-style: solid;
  border-width: 2px;
  border-color: #2cd5c4;
  padding: 10px;
  font-family: sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: small;
  cursor: pointer;
  /* opacity: 9; */
  z-index: 9;
  text-wrap: nowrap;
}
.prod-btn:hover{
  background-color: aliceblue;
  border-color: #2cd5c4;
  color: #000000;
}






/* @media screen and (max-width: 1760px){
  .prod-items p{
    text-wrap: wrap;
  }
} */

@media screen and (max-width: 1760px) {
  .prod-items img{
    height: 330px;
  }
  /* .prod-items h3{
    font-size: large;
  } */
  .prod-items p{
    font-size: medium;
    text-wrap: wrap;
  }
  .prod-items h4{
    /* text-wrap: wrap; */
    font-size: medium;
  }
  .prod-btn{
    padding: 9px;
    padding-left: 6px!important;
    padding-right: 6px!important;
    text-wrap: nowrap;
    letter-spacing: normal;
  }
  
}

@media screen and (max-width: 1200px) {

  .popup-inner {
    width: 40%;
    top: 50%;
  }

  .Idx1 {
    margin-top: 150px;
  }

  .contain1 img {
    width: 100%;
    object-fit: contain;
    box-shadow: none;
  }

  .text2 {
    width: 57%;
  }
  /* .review-contain{
    margin: 20px;
  } */

  .prod-prop{
    width: 100%;
  }
}


@media screen and (max-width: 992px) {

  .popup-inner {
    width: 50%;
    /* top: 38%; */
  }
  .Idx1{
    flex-direction: column-reverse;
  }
  .Indexp::before{
    height: 60%;
  }

  .Itext {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
  }

  #sp1 {
    text-align: center;
  }

  #imag1 {
    text-align: center;
  }

  .contain1 {
    flex-direction: column-reverse;
  }

  .contain21 {
    flex-direction: column;
  }

  .text2 {
    width: 100%;
  }
  .prod-list{
    flex-direction: column;
  }
  .prod-content .row-cols-2>* {
    width: 100%;
  }  
  #sp2{
    text-align: center;
  }
  .prod-items img{
    height: 310px;
  }
  .prod-items p{
    /* font-size: medium; */
    text-wrap:wrap;
    
  }
}

@media screen and (max-width: 768px) {

  .imgNv{
    height: 160px;
  }

  .popup-inner{
    top: 50%;
  }
  .whatsapp-btn img{
    height: 65px;
  }
  #sp1{
    font-size: 50px;
  }
  .Idx1 {
    flex-direction: column-reverse;
    margin-top: 200px;
  }

  .index-btn{
    margin-bottom: 5px;
  }

  .contain1 {
    flex-direction: column-reverse;
  }

  .contain21 {
    flex-direction: column;
  }

  .contact2-subcontain {
    flex-direction: column;
  }

  .socialMedia {
    padding-bottom: 50px;
  }

  .smedia-contain .col {
    text-align: center;
  }

  .smedia-contain img {
    width: 80%;
  }

  .smedia-contain p {
    font-size: 23px;
  }

  .Rowl .owl-next span {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 25px;
  }

  .Rowl .owl-prev span {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 25px;
  }
  #map-img{
    width: 100%;
  }
  .prod-items{
    flex-direction: row;
  }
  .prod-items img{
    height: 270px;
  }
  .prod-btn{
  letter-spacing: 0;
  }
}

@media screen and (max-width: 576px) {
  
  .navItem{
    font-size: 16px;
  }
  #nav-sub-Item {
    font-size: 13px;
  }
  .Indexp{
    min-height: 75vh;
  }
  .imgNv{
    height: 125px;
  }
  .popup-inner {
    width: 80%;
    top: 65%;
  }

  .index-btn{
    font-size: medium;
    /* padding: 10px; */
    padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px !important;
        padding-right: 10px !important;
  }

  .blob {
    width: 150px;
    top: 200%;
    background: linear-gradient(50deg,
        rgba(255, 88, 139, 1) 21%,
        rgb(0 164 173) 67%,
        rgba(118, 74, 166, 1) 81%);
  }

  #sp1 {
    font-size: 30px;
  }

  #sp2 {
    font-size: 40px;
    display: flex;
    justify-content: center;
  }

  #sp3 {
    font-size: 30px;
  }

  .Usp {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .Usp1{
    padding-top: 0;
    margin-top: 170px;
  }
  .contain-image{
    height: 80%;
  }

  .contain1 p {
    font-size: medium;
  }

  .text2 {
    font-size: 30px;
  }

  .text1 h3 {
    font-size: 25px;
  }

  .text1 p {
    font-size: 18px;
    padding: 10px;
  }

  .loginpage {
    padding: 0;
  }

  .Signuppage {
    padding: 0;
  }

  .Passwordpage {
    padding: 0;
  }

  .Idx1 {
    flex-direction: column-reverse;
  }

  .contain1 p {
    text-wrap: wrap;
  }

  .contain1 {
    flex-direction: column-reverse;
  }

  .contain1 img {
    height: 85%;

  }
  .smedia-contain p {
    font-size: 15px;
  }
  .slick-prev{
    left: -6px;
  }
  .slick-next{
    right: -6px;
  }

  .contain21 img{
    height: 85px;
  }

  .contain21 p{
    font-size: small;
  }
  .contain3 p{
    font-size: 15px;
  }
  .Bottomp{
    padding-bottom: 0;
  }
  .service-btn{
    padding: 7px;
    font-size: small;
  }
  .connect-btn{
    padding: 7px;
    font-size: small;
  }
  .Reviewp p{
    font-size: small;
  }
  /* .slick-prev:before{
    margin-left: 20px;
  } */
  /* .slick-next:before{
    margin-left: -34px;
  } */
  .Signuppage .box1{
    margin-top: 155px;
  }
  .Blogpage{
    margin-top: 165px;
  }
  .blogs{
    overflow: hidden;
  }
  .contain3{
    flex-direction: column-reverse;
    padding: 10px;
  }
  .Bottomp .col{
    margin-bottom: 10px;
  }
  .contain3 ul{
    padding-left: 0;
  }

  .Eng-content{
    padding-top: 210px;
  }
  .prod-box{
    padding: 30px;
  }
  .prod-prop{
    padding: 0;
  }
  .prod-items{
    flex-direction: row;
  }
  .prod-items img{
    height: 220px;
  }

  .prod-items h4{
    font-size: smaller;
    font-weight: bold;
    text-wrap: wrap;
  }
  .prod-items p{
    font-size: smaller;
    text-wrap:wrap;
  }
  .prod-items h3{
    font-weight: bold;
    font-size: small;
  }
  .prod-btn{
    padding-left: 3px!important;
    padding-right: 3px!important;
    padding: 6px;
  }
}

@media screen and (max-width: 430px){

  .imgNv{
    height: 100px;
  }
  .prod-box{
    padding: 20px;
  }
  .prod-items img{
    height: 172px;
  }
  .price-tag{
    flex-direction: column;
    text-align: center;
  }
  .prev-price{
    margin: 2px;
  }
  #sp2{
    font-size: 25px;
  }
  .contain1 p{
    font-size: small;
  }
  .text1 p{
    font-size: medium;
  }
}

@media screen and (max-width: 390px){

  .prod-items h4{
    font-size: x-small;
    font-weight: bold;
    text-wrap: wrap;
  }
  .prod-items p{
    font-size: x-small;
    text-wrap:wrap;
  }
  .prod-items h3{
    font-weight: bold;
    font-size: x-small;
  }
  .prod-btn{
    padding-left: 1px!important;
    padding-right: 1px!important;
    padding: 3px;
    font-size: x-small;
  }
}